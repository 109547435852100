export default {
  LOCATIONS_LIST: "List of concessions",
  ADD_LOCATION: "Add a concession",
  LOCATION_ADDED: "Concession added",
  LOCATIONS_UPDATED: "Modified concession",
  EDIT_LOCATION: "Modify this concession",
  DELETE_THIS_LOCATION: "Remove this concession?",
  LOCATION_DELETED: "Deleted concession",
  VIEW_LOCATION: "Concession",
  NONE: "None",
};
