export default {
  OPTIONS_LIST: "Settings list",

  MODULE: "module",
  NAME: "nom",
  VALUE: "Value",
  MODULE_GENERAL: "General",
  MODULE_PURCHASES: "Purchases",
  MODULE_ESTIMATION: "Estimations",
  MODULE_INSPECTION: "Inspections",
  MODULE_REPAIR: "Reparations",
  MODULE_SALES: "Sales",
  OPTION_DOCUMENT_FOOTER: "Document page",

  OPTION_DEFAULT_TAXES_GROUP: "Default tax group",
  OPTIONS_DEFAULT_ESTIMATION_ITEM_HOURLY_RATE:
    "Default hourly rate of the estimate",
  OPTION_DEFAULT_INSPECTION_COMPLETED_CALLBACK_URL:
    "Inspection completed callback URL",
  OPTION_DEFAULT_PENDING_APPROVE_ESTIMATION_CALLBACK_URL:
    "Pending approve estimation callback URL",
  OPTION_DEFAULT_ESTIMATION_APPROVED_CALLBACK_URL:
    "Estimation approved callback URL",
  OPTION_DEFAULT_REPAIR_COMPLETED_CALLBACK_URL:
    "Reparation completed callback URL",
};
